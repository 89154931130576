@use 'variables';
@use 'mixins';

shared-labeled-value-inline-container {
  overflow: hidden;

  //h2 {
  //  &.danger {
  //    color: variables.$color-red;
  //  }
  //}
  //
  //.container {
  //  display: grid;
  //
  //  .container-overflow {
  //    overflow-y: auto;
  //    max-height: 30rem;
  //  }
  //}

  .labeled-value-inline-container-list {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;

    > * {
      &:first-child {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }
      &:last-child {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
    }

    &.hasBorder {
      border: variables.$border;
    }

    &.success {
      border: 1px solid variables.$color-green;
    }

    &.danger {
      border: 1px solid variables.$color-red;
    }

    > *:not(:last-child) {
      border-bottom: variables.$border;
    }
  }
}
