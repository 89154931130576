@use 'variables';
@use 'mixins';

.p-inputotp-input {
  height: variables.$form-input-height;
  transition: 0.3s ease all;
  color: variables.$color-primary-text;
  background-color: variables.$color-light;
  border: variables.$border-form-input;
  border-radius: 1rem;
  width: 100%;
  max-width: 3rem;

  &:focus {
    @include mixins.formInput_focus();
  }

  &.ng-invalid.ng-touched {
    @include mixins.formInput_error();
  }
}
