.page-container {
  padding: 4rem 1.25rem;
}

.max-mobile-view {
  max-width: 40rem;
}

.v-h-centered {
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: minmax(0, 1fr);
}

.h-centered {
  height: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}

.touch-pan-y {
  touch-action: pan-y !important;
}

.desktop-detail-wrapper {
  max-width: 40rem;
  min-height: 100vh;
  margin: auto;
}
