@use 'variables';

shared-labeled-value-inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  color: variables.$color-primary-text;

  .content {
    display: flex;
    max-width: 75%;
    align-items: center;
    justify-content: flex-end;
  }

  b {
    text-align: right;
    max-width: 75%;
    overflow: hidden;

    @media (min-width: variables.$responsive-breakpoint-mobile) {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
