@use 'mixins';
@use 'variables';
@use 'sizing';
@use 'components';
@use 'colors';
@use 'animation';
@use 'overrides';

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&amp;subset=latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import '@angular/cdk/overlay-prebuilt.css';

html,
body {
  height: 100%;
  color: var(--dark);
}

body {
  overflow: hidden;
}

body,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

* {
  font-family: 'Raleway', 'Lato', sans-serif;
  font-variant-numeric: lining-nums proportional-nums;
  outline: none;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

input {
  border: none;
  background: none;
}

body {
  line-height: 1;
}

img {
  user-select: none;
}

label {
  cursor: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

// TODO: Delete? What is it?
iframe#launcher-frame {
  z-index: 100 !important;
}

// Scrollbar
@include mixins.lt-md() {
  ::-webkit-scrollbar {
    width: 0;
    display: none;
    -webkit-appearance: none;
  }
}

@include mixins.gt-sm() {
  ::-webkit-scrollbar {
    width: 18px;
    height: 18px;
  }
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 6px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  opacity: 0.5;
  background-color: rgba(#95a5b2, 0.2);
  border-radius: 14px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(#95a5b2, 0.5);
  height: 6px;
  border: 6px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 14px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

// Hide elements for certain screen width
.hide-xs {
  @media screen and (max-width: variables.$screen-xs-max) {
    display: none;
  }
}

.hide-gt-xs {
  @media screen and (min-width: variables.$screen-sm-min) {
    display: none;
  }
}

.hide-sm {
  @media screen and (max-width: variables.$screen-sm-max) {
    display: none;
  }
}

.hide-gt-sm {
  @media screen and (min-width: variables.$screen-md-min) {
    display: none;
  }
}

// CUSTOM
.cp-max-width-40rem {
  max-width: 40rem;
}
